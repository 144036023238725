/* eslint-disable @next/next/no-before-interactive-script-outside-document */

import Script from "next/script";

const isGAEnabled = process.env.NEXT_PUBLIC_GA_ENABLED === "true";

export const GAScript = () => {
  if (!isGAEnabled) return null;

  return (
    <>
      <Script
        strategy="beforeInteractive"
        async
        src="https://www.googletagmanager.com/gtag/js?id=G-ZGC5BXJGL5"
      />
      <Script id="ga" strategy="beforeInteractive">
        {`
window.dataLayer = window.dataLayer || [];
function gtag(){dataLayer.push(arguments);}
gtag('js', new Date());
gtag('config', 'G-ZGC5BXJGL5');
`}
      </Script>
    </>
  );
};
